import {
  Login, Dashboard, Register, Recover, RecoverMessage,
  AuthChangePass, CreateAdmin, CreateOrg, AddOrgAdmin, Endorsement, AddBadge, BadgeList,
  RecoverReset, NoOrgUsers, OrgBadges, OrgDetails, UserDetails, NoOrgAdmins, AddBadgeToOrg, UserBadges,
  CreateEvent, ViewBadgeEvents, AllBadgeEndorsements, NotHiddenEndorsements, HiddenEndorsements, UnverifiedUsers, EndorsementsReport,
  OwnerSkillManager, OwnerRTE, UserSkillManager, UserSkillEvidenceManager, UserSkillRTE
} from './components/index';
import { useAppProvider } from './provider';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Organizations from "./components/Dashboard/Organizations";
import Main from "./components/Dashboard/Main";
import Users from "./components/Dashboard/Users";
import Admin from "./components/Dashboard/Admins";
import ConfirmEmail from "./components/pages/ConfirmEmail";
import SkillViewComponent from "./components/skills/SkillResource";
import MyEndorsementsPage from "./components/EndorsementViewPage";
import Page from "./components/pages/Page";
import NoAuthPage from './components/pages/NoAuthPage';
import NotFoundPage from './components/pages/NotFoundPage';

export default function RoutesElement({ user }) {
  return (
    <>
      <Routes path="/">
        <Route index element={<NoAuthPage content={Login} />} />
        <Route path="endorsement" element={<NoAuthPage content={Endorsement} />} />
        <Route path="/skill/:skillTitle" element={<Page content={SkillViewComponent} />} />
        <Route path="/evidence/:userEmail/:skillTitle/:evidenceTitle" element={<Page content={SkillViewComponent} />} />
        <Route path="user-skill/:userEmail/:skillTitle" element={<Page content={UserSkillEvidenceManager} />} />
        <Route path="skills" element={<Page content={UserSkillManager} />} />

        <Route element={<UnauthenticatedRoutes />}>
          <Route path="recover-reset" element={<NoAuthPage content={RecoverReset} />} />
          <Route path="recover-message" element={<NoAuthPage content={RecoverMessage} />} />
          <Route path="recover" element={<NoAuthPage content={Recover} />} />
          <Route path="login" element={<NoAuthPage content={Login} />} />
          <Route path="register" element={<NoAuthPage content={Register} />} />
          <Route path="confirm-email" element={<NoAuthPage content={ConfirmEmail} />} />

          <Route element={<UnauthenticatedRoutes />}>
            <Route path="profile-landing/:username" element={<Page content={Main} />} /> {/* Capture username here */}
            <Route path="/404" element={<NotFoundPage />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route path="auth-change-pass" element={<Page content={AuthChangePass} />} />
          <Route path="dashboard" element={<Main />} />
          <Route path="organizations" element={<Organizations />} />
          <Route path="users" element={<Page content={Users} />} />
          <Route path="admins" element={<Page content={Admin} />} />
          <Route path="badges" element={<BadgeList />} />
          <Route path="org-badges" element={<OrgBadges />} />
          <Route path="mybadges" element={<UserBadges />} />
          <Route path="org-details" element={<OrgDetails />} />
          <Route path="user-details" element={<UserDetails />} />
          <Route path="edit-evidence/:skillTitle/:descriptorTitle" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={UserSkillRTE} /></RoleProtectedRoutes>} />
          <Route path="endorsements-hidden" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={HiddenEndorsements} /></RoleProtectedRoutes>} />
          <Route path="endorsements-not-hidden" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={NotHiddenEndorsements} /></RoleProtectedRoutes>} />
          <Route path="endorsements-report" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={EndorsementsReport} /></RoleProtectedRoutes>} />
          <Route path="all-endorsements-for-badge" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={AllBadgeEndorsements} /></RoleProtectedRoutes>} />
          <Route path="view-events" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={ViewBadgeEvents} /></RoleProtectedRoutes>} />
          <Route path="create-event" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={CreateEvent} /></RoleProtectedRoutes>} />

          <Route path="dashboard-protected" element={<RoleProtectedRoutes roles={['ROLE_ADMIN', 'ROLE_OWNER']}><Page content={Dashboard} /></RoleProtectedRoutes>} />
          <Route path="add-badge-to-org" element={<RoleProtectedRoutes roles={['ROLE_ADMIN', 'ROLE_OWNER']}><Page content={AddBadgeToOrg} /></RoleProtectedRoutes>} />

          <Route path="create-admin" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={CreateAdmin} /></RoleProtectedRoutes>} />
          <Route path="create-org" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={CreateOrg} /></RoleProtectedRoutes>} />
          <Route path="org-add-admin" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={AddOrgAdmin} /></RoleProtectedRoutes>} />
          <Route path="skill-manager" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={OwnerSkillManager} /></RoleProtectedRoutes>} />
          <Route path="skill-manager/:skillTitle" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={OwnerRTE} /></RoleProtectedRoutes>} />
          <Route path="add-badge" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={AddBadge} /></RoleProtectedRoutes>} />
          <Route path="no-org-users" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={NoOrgUsers} /></RoleProtectedRoutes>} />
          <Route path="no-org-admins" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={NoOrgAdmins} /></RoleProtectedRoutes>} />
          <Route path="all-unverified-users" element={<RoleProtectedRoutes roles={['ROLE_OWNER']}><Page content={UnverifiedUsers} /></RoleProtectedRoutes>} />
          <Route path="my-endorsements" element={<RoleProtectedRoutes roles={['ROLE_USER']}><Page content={MyEndorsementsPage} /></RoleProtectedRoutes>} />
        </Route>

      </Routes>
    </>
  );
}

export function ProtectedRoutes({ children }) {
  const { user } = useAppProvider();
  console.log("USER: ", user);
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
}

export function UnauthenticatedRoutes({ children }) {
  const { user } = useAppProvider();
  if (!!user) {
    // TODO PMG dashboard gets the hose again
    // return <Navigate to="/dashboard" replace />;
  }
  return <Outlet />;
}

export function RoleProtectedRoutes({ children, roles = [] }) {
  const { user } = useAppProvider();
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (roles.filter(role => user?.roles.some(r => r === role)).length === 0) {
    return <Navigate to="/dashboard?permission_denied=true" replace />;
  }

  return children;
}
