import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SkillCards.css';
import { sendEndpointRequest, sendEndpointRequestNoAuth } from "../../iop/iop";
import { SkillEndpoints } from "../../api/Endpoints";
import { toast } from "react-toastify";

/**
 * Skill Cards
 * 
 * Used to display a list of Skills. This component has two modes: Owner mode and Public mode
 *
 */
const SkillCards = ({ authorized, isOwner, userEmail }) => {
  const [skills, setSkills] = useState([]);
  const [skillImages, setSkillImages] = useState([]);
  const [contents, setContents] = useState([]);
    //const [selectedSkill, setSelectedSkill] = useState(null);
    const navigate = useNavigate();

    const fetchExistingSkills = async () => {
      let response;

      try {
        /* If the owner is viewing the page make an authorized request to get all
           of the skills (even the unpublished ones) */
        if (isOwner) {
          response = await sendEndpointRequest(SkillEndpoints.endpoints.getAllSkills, null, null, null);

        /* Otherwise, for everyone else, use an aunthorized endpoint */
        } else {
          response = await sendEndpointRequestNoAuth(SkillEndpoints.endpoints.getAllPublicSkills, null, null, null);
        }
        } catch (error) {
          toast.error("It looks like an error occurred. Please try again later.");
          return;
        }
  
        /* Handle an errors from the response */
        if(response?.success == false) {
          toast.error("Server Error! Failed to load existing skills. Please try again later.");
          return;
        }
        setSkills(response.data);
    };


    const handleViewClick = (skill) => {
      navigate(`/skill/${skill.title}`);
    };

  /**
   * Fetch skill content and store it in the state. Parse the content to remove images,
   * remove HTML elements, and limit the number of characters.
   * 
   * @param {string} skillTitle 
   * @returns 
   */
  const fetchContent = async (skillTitle) => {
    const numberofDisplayedContentChars = 300;

    if (!skillTitle) return;

    /* Do the fetching */
    let response = null;
    response = await sendEndpointRequestNoAuth(SkillEndpoints.endpoints.getSkillContext, null, null, { "skill-title": skillTitle });
    
    /* Handle failure to fetch content. No need to throw an error, we just 
      display any content on the card. */
    if(!response?.success || !response?.data || !response?.data?.txtContent) {
      return "";
    }

    var content = response.data.txtContent.toString() || "";

    /* Remove images from the content */
    while (content.includes("<img")) {
      var start = content.indexOf("<img");
      var end = content.slice(start).indexOf(">") + start + 1;
      var imgString = content.slice(start, end);
      if (skillImages[skillTitle]) {
        content = content.slice(0, start) + content.slice(end);
      } else {
        var srcString = imgString.slice(imgString.indexOf("src"));
        var srcStart = srcString.indexOf("\"");
        var srcEnd = srcString.indexOf("\"", srcStart + 1);
        srcString = srcString.slice(srcStart + 1, srcEnd);
        setSkillImages(prev => ({ ...prev, [skillTitle]: srcString }));
        content = content.slice(0, start) + content.slice(end);
      }
    }

    /* remove HTML elements */
    content = content.replace(/<\/?(p|h2|h3|h4|figure class="image"|figure|ul|li|blockquote|a|strong)>/g, "");
    content = content.replace("&nbsp;", "");

    /* limit characters */
    if (content?.length > numberofDisplayedContentChars) {
      content = content.slice(0, numberofDisplayedContentChars);
    }
    setContents(prev => ({ ...prev, [skillTitle]: content }));
    return content;
  };

  useEffect(() => {
    fetchExistingSkills();
  }, [ /* ensure fetch is only called once */ ]);

  useEffect(() => {
    skills?.forEach(skill => {
      fetchContent(skill.title);
    });
  }, [skills]);

  return (
    <div className="skill-cards-container">
      {skills?.length > 0 ? (
        skills.map((skill) => (
          <div key={skill.skillId} className="skill-card">
            <img
              src={skillImages[skill.title] || `https://picsum.photos/1280/720?random=${Math.random()}`}
              alt={skill.title}
              className="skill-card-image"
            />
            <div className="skill-card-content">
              <h3>{skill.title}</h3>
              <p>{contents[skill.title] || ""}</p>
              <div className="skill-card-buttons">
                <button onClick={() => handleViewClick(skill)} className="view-button">
                  View
                </button>
                {authorized && <button onClick={() => navigate(`/user-skill/${userEmail}/${skill.title}`)} className="edit-button">
                  My Evidence
                </button>}
                {isOwner && authorized && (
                  <button onClick={() => navigate(`/skill-manager/${skill.title}`)} className="edit-button">
                    Update Content
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>
          {isOwner
            ? "No skills found. Please create a new skill."
            : "No skills found. Come back later to see if any have been added."}
        </p>
      )}
    </div>
  );
};

export default SkillCards;

