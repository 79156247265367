import React, { useState, useEffect, useContext, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from '../../Constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../provider";
import { sendEndpointRequest } from "../../iop/iop";
import { EndorsementEndpoints, SkillEndpoints } from "../../api/Endpoints";
import { useParams } from 'react-router-dom';
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { sleep } from "../../Utils";
import EndorsementRequestPopup from  "../popups/EndorsementRequestPopup";
import SubmissionPopup from  "../popups/SubmissionPopup";
import { SkillRequestPublishEvidence } from "../../api/skill";

import './TitleEditor.css';

const UserSkillRTE = () => {
  const { user } = useContext(UserContext);
  const { skillTitle } = useParams();
  const { descriptorTitle } = useParams();
  const [saving, setSaving] = useState(false);
  const [waitingOn, setWaitingOn] = useState('');
  const typingTimeoutRef = useRef(null);
  const [titleInput, setTitleInput] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const [content, setContent] = useState('');
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [evidenceTitle, setSkillDescriptor] = useState("");
  const [publicallyViewable, setPublicallyViewable] = useState(false);
  const [showEndorsements, setShowEndorsements] = useState(false);
  const [requestingEndorsement, setRequestingEndorsement] = useState(false);
  const [endorsements, setEndorsements] = useState([]);

  const navigate = useNavigate();

  const getToken = () => {
    const token = localStorage.getItem('token');
    return token ? token.toString() : '';
  };

  const handleSaveContent = async (contentToSave) => {
    if (!contentToSave || !initialLoadFinished) {
      setWaitingOn("");
      return;
    }
    setSaving(true);
    try {
      /* Make save request */
      const pathVars = {
        "user-name": user.email,
        "skill-title": skillTitle,
        "evidence-title": evidenceTitle
      };

      const response = await sendEndpointRequest(SkillEndpoints.endpoints.saveUserSkillContent, null, { content: contentToSave }, pathVars);
  
      /* Handle any API errors */
      if (!response.success || !response.data) {
        toast.error(`Failed to save user skill content: ERROR: ${response.error?.code || 'Unknown error'}`);
      }
  
    } catch (error) {
      toast.error("Error Saving Skill Content");

    } finally {
      /* Wait a couple of seconds before returning to non-saving state.
         This ensures that quick updates are actually noticed by the user  */
      await sleep( 2000 );

      setSaving(false);
      setWaitingOn("");
    }
  };

  const handleUpdateTitle = async (titleToSave) => {
    if (!titleToSave || titleToSave === "" || titleToSave === currentTitle) {
      setWaitingOn("");
      return;
    }
    setSaving(true);

    try {
      const pathVars = {
        "user-name": user.email,
        "skill-title": skillTitle,
        "evidence-title": evidenceTitle
      };
      const requestParam = {
        replacement: titleToSave 
      };

      const header = {
        Authorization: `Bearer ${getToken()}`,
      };

      //const response = await sendEndpointRequest(SkillEndpoints.endpoints.updateEvidenceContentTitle, null, pathVars, requestParam);
      console.log(`${config.url.API_BASE_URL}/api/skill/edit-content-title/${ user.email }/${skillTitle}/${evidenceTitle}`);
      const response = await axios.post(
        `${config.url.API_BASE_URL}/api/skill/edit-content-title/${user.email}/${skillTitle}/${evidenceTitle}`,
        null,  // No body content needed
        {
          params: { replacement: titleToSave },  // Send replacement as a query parameter
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );


      /* Handle any API errors */
      if (!response.status === 200) {
        throw new Error(`Failed to save user skill content title: ERROR: ${response.error?.code || 'Unknown error'}`);
      }

      /* Update the title and refresh the page */
      setCurrentTitle(titleToSave);
      navigate(`/edit-evidence/${skillTitle}/${titleToSave}`);

    } catch (error) {
      console.error(`{OwnerRTE} Save Skill Title Error Response: `, error);
      alert("Error Saving Skill Title");
      await new Promise(resolve => setTimeout(resolve, 1000));

    } finally {
      setSaving(false);
      setWaitingOn("");
    }
  };

  const handleTitleChange = (event) => {
    if (waitingOn === "content") return;
    setWaitingOn("title");
    const data = event.target.value;
    setTitleInput(data);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      handleUpdateTitle(data);
    }, 1500);

  };

  const handleChange = (event, editor) => {
    if (waitingOn === "title") return;
    setWaitingOn("content");
    const data = editor.getData();
    setContent(data);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      handleSaveContent(data);
    }, 1000); 
  };

  const handleEndorsementItemClicked = (item) => {
    /* Place the endorsement data in a semi-formatted string */
    const endorsement = `${item.firstName} ${item.lastName}\n\n${item.email}\n${item.phoneNumbeString}\n\n${item.message}`;

    navigator.clipboard.writeText(endorsement).then(() => {
      toast.success("Copied endorsement to clipboard!");
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    const response = await fetch(`${config.url.API_BASE_URL}/api/skill/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body: formData,
    });

    const result = await response.json();
    // Add the image URL to the uploadedImages state
    setUploadedImages((prev) => [...prev, result.data]);
    console.log("Uploaded Image: ", result);
    return result;
  };

  const deleteImageFromServer = async (imageUrl) => {
    // await fetch(`http://localhost:8080/api/skill/delete-image`, {
    //     method: 'DELETE',
    //     headers: {
    //         Authorization: `Bearer ${getToken()}`,
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ imageUrl }),
    // });
    console.log("TODO - DELETE IMAGE FROM SERVER: ", imageUrl);
  };

  const detectDeletedImages = (newContent) => {
    // Extract current image URLs from the content
    const currentImageUrls = Array.from(new DOMParser().parseFromString(newContent, 'text/html').querySelectorAll('img')).map(img => img.src);

    // Determine which images have been deleted
    const deletedImages = uploadedImages.filter(url => !currentImageUrls.includes(url));

    // If there are deleted images, remove them from the server
    deletedImages.forEach(async (url) => {
      await deleteImageFromServer(url);
    });

    // Update uploadedImages to reflect the current state
    setUploadedImages(currentImageUrls);
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    upload() {
      return this.loader.file.then((file) => {
        return new Promise((resolve, reject) => {
          uploadImage(file)
            .then((result) => {
              resolve({
                default: result.data,
              });
            })
            .catch((error) => {
              console.log("Error in Upload RTE Image (User): ", error);
              reject(error);
            });
        });
      });
    }

    abort() { }
  }

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        handleSaveContent(content);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [content]);

  const fetchAPIData = async () => {
    /* Fetch evidence context object */
    let response;
    try {
      response = await axios.get(
        `${config.url.API_BASE_URL}/api/skill/${user.email}/${skillTitle}/${descriptorTitle}`,
        // `${config.url.API_BASE_URL}/api/skill/${encodeURIComponent(user.email)}/${encodeURIComponent(skill.title)}/${encodeURIComponent(userSkillContent.contentTitle)}`, 
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      console.log("RECIEVED NEW USER SKILL CONTEXT: ", response);

      setContent(response?.data?.txtContent ?? '');
      setSkillDescriptor(response?.data?.contentTitle);
      setPublicallyViewable(response?.data?.publiclyViewable);
      setTitleInput(descriptorTitle);
      const IdResponse = await axios.get(`${config.url.API_BASE_URL}/api/skill/${skillTitle}/${descriptorTitle}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
      });
      console.log("RECIEVED NEW USER SKILL CONTEXT: ", IdResponse);

    } catch (error) {
      console.error("Error fetching skill content: ", error);
    } finally {
      setInitialLoadFinished(true);
    }

    /* Fetch received endorsements */
    try {
      response = await sendEndpointRequest(EndorsementEndpoints.endpoints.getReceivedEndorsements, null, null, { "user-name": user.email, "skill-title": skillTitle, "content-title": descriptorTitle });
    } catch (error) {
      console.error("Error fetching endorsements: ", error);
    }

    if(response?.data) {
      setEndorsements(response.data);
    }
  };

  const handlePublishContent = async () => {
    let response = await SkillRequestPublishEvidence(evidenceTitle, user.email, skillTitle);
  
    if (!response.success) {
      toast.error("Failed to publish skill content");
    } else {
      setPublicallyViewable(true);
      toast.success("Skill content published successfully");
    }
  };

  const handleRequestEndorsement = () => {
    setRequestingEndorsement(prev => !prev);
  };

  const handleImportEndorsements = () => {
    setShowEndorsements(prev => !prev);
  };

  useEffect(() => {
    fetchAPIData(); // Fetch content when component mounts
  }, [skillTitle, descriptorTitle]);


  return (
    <div style={{ width: '100%' }}>

      <div style={{ display: 'flex', width: '100%', marginTop: '1em' }}>
        <div className="editor-container" style={{
          flex: '1',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          padding: '0em',
        }}>
            <p disabled={saving}
              sx={{ 
               left: '50%',
              }}
            >
              {saving ? 'Autosave..' : 'Up to Date'}
            </p>
          <div className="header-container">
            
            <div className="title-editor">
              <input
                type="text"
                className="title-editor-text"
                value={titleInput}
                onChange={handleTitleChange}
                disabled={saving || waitingOn === "content"}
              ></input>
            </div>
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={handleChange}
            disabled={waitingOn === "title"}
            config={{
              extraPlugins: [CustomUploadAdapterPlugin],
              toolbar: [
                'heading', '|',
                'fontSize',
                'fontColor',
                'bold', 'italic', 'underline', 'strikethrough', '|',
                'link', 'bulletedList', 'numberedList', '|',
                'alignment', 'fontBackgroundColor', '|',
                'blockQuote', 'insertTable', 'horizontalLine', '|',
                'undo', 'redo', 'removeFormat', 'imageUpload'
              ],
              fontSize: {
                options: [
                  9,
                  11,
                  13,
                  17,
                  21,
                  25,
                  'default',
                  'Huge',
                  'Big',
                  'Small'
                ],
                supportAllValues: true
              },
            }}
          />
        </div>

        <div style={{ position: 'relative', width: '300px', height: '100vh' }}>

        <button onClick={handleRequestEndorsement} style={{
            marginBottom: '10px',
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "uppercase",
            width: "90%",
          }}>
            {requestingEndorsement ? "Collapse" : "Request Endorsement"}
          </button>
          {requestingEndorsement && publicallyViewable &&
            <EndorsementRequestPopup
              title={"Request Endorsement from a colleague, friend, mentor, former employer, etc. Once the request "
                      + "is made, the recipient will receive an email with a link to endorse your skill. They will be able to view the content you have created here."}
              onClose={() => handleRequestEndorsement()}
              userEmail={user.email}
              skillTitle={skillTitle}
              contentTitle={evidenceTitle}
            />}

          {requestingEndorsement && !publicallyViewable &&
            <SubmissionPopup
              header={"Skill Not Publically Viewable"}
              message={"You must publish the content you created in order to request an endorsement."}
              altButton1Title={"Publish now"}
              altButton1Action={() => handlePublishContent()}
              onClose={() => handleRequestEndorsement()}
            />}

          <button onClick={handleImportEndorsements} style={{
            marginBottom: '10px',
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "uppercase",
            width: "90%",
          }}>
            {showEndorsements ? "Hide" : "Import Endorsements"}
          </button>

          {showEndorsements && (
            <div style={{
              width: '300px',
              backgroundColor: '#f8f9fa',
              padding: '20px',
              borderLeft: '1px solid #ccc',
              height: '100vh',
              overflowY: 'auto',
              textAlign: 'left'

            }}>
              <h5
                style={{
                  textAlign: 'left',
                  fontSize: '1.5em',
                  fontWeight: 900,
                  fontFamily: 'Arial, sans-serif',
                  marginBottom: '0.5em',
                }}
              >Endorsements</h5>
              <h5
                style={{
                  textAlign: 'left',
                  fontSize: '1em',
                  fontFamily: 'Arial, sans-serif',
                  marginBottom: '1em',
                  color: '#7a7a7a'
                }}
              >Select an item to place in your portfolio</h5>
              {endorsements.map((endorsement, index) => (
                <Box key={index} sx={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '10px',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  "&:hover": {
                    backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde)',
                  },
                }}
                  onClick={() => {handleEndorsementItemClicked(endorsement)}}
                >
                  <h4>{endorsement.firstName} {endorsement.lastName}</h4>
                  <h6><strong>{endorsement.email}</strong></h6>
                  <h6>{endorsement.phoneNumbeString}</h6>
                  <p>{endorsement.message}</p>
                </Box>
              ))}
            </div>
          )}
        </div>

      </div>
    </div>
  );
    
    

};

        export default UserSkillRTE;